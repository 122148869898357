/* eslint-disable max-classes-per-file */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  SubscriberMap,
  BRIDGE_MESSAGE,
  WebBridgeMessage,
  EventDataToSend,
  MessageResponsePayload,
  AcceptedBridgeMessage,
} from './webBridge.types';

const BADGE_READER_TIMEOUT = 10 * 1000;
export const ERROR_BADGE_TIMEOUT = 'ERROR_BADGE_TIMEOUT';
export class TimeoutError extends Error {
  constructor() {
    super();

    this.message = ERROR_BADGE_TIMEOUT;
  }
}
declare global {
  const WebViewBridge: {
    send: (serializedMessage: string) => void;
    onMessage: (serializedMessage: string) => void;
  };
}

function isEventDataToSend(
  data: AcceptedBridgeMessage,
): data is EventDataToSend {
  if (typeof data === 'object' && 'name' in data) {
    return true;
  }
  return false;
}

function onKeyPress(
  { keyCode, key }: { keyCode: number; key: string },
  externalArray: string[],
  callBack: (message: string) => void,
) {
  if (keyCode === 13 || key === 'enter' || key === 'Enter') {
    callBack(externalArray.join(''));
    externalArray = [];
  } else if (key) {
    externalArray.push(key);
  } else if (keyCode !== 10) {
    externalArray.push(String.fromCharCode(keyCode));
  }
}

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export class WebBridge {
  private subscribers: SubscriberMap;

  private searchPromiseResolver: null | ((value: boolean) => void) = null;

  public ready: boolean;

  constructor() {
    this.ready = false;
    this.searchConnection();
    this.subscribers = this.initSubscribtion();
  }

  searchConnection = async () => {
    await sleep(600);
    if (typeof WebViewBridge !== 'undefined') {
      if (this.ready) {
        return this;
      }

      this.injectOnMessageMethod();

      this.ping();

      return new Promise(resolve => {
        this.searchPromiseResolver = resolve;

        // resolving null if no printers found
        setTimeout(() => {
          resolve(false);
        }, 5000);
      });
    }

    console.log('Undefined');
    this.ready = false;
    return false;
  };

  injectOnMessageMethod = () => {
    WebViewBridge.onMessage = (serializedMessage: string) => {
      try {
        const messageObject = this.unserializeMessage(serializedMessage);
        this.dispatchEventToSubscribers(messageObject);

        if (messageObject.eventName === BRIDGE_MESSAGE.PONG) {
          if (!this.ready) {
            this.acknowledgeConncetion();
            return true;
          }
          this.pong();
        }
      } catch (error) {
        this.emitMessage(BRIDGE_MESSAGE.ERROR_CATCH, null);
      }
      return this;
    };
  };

  private acknowledgeConncetion = (): void => {
    this.ready = true;
    if (this.searchPromiseResolver) {
      this.searchPromiseResolver(true);
      this.searchPromiseResolver = null;
    }
  };

  private initSubscribtion = (): SubscriberMap => {
    const eventNames = Object.keys(BRIDGE_MESSAGE) as BRIDGE_MESSAGE[];
    const map: SubscriberMap = {};
    // eslint-disable-next-line array-callback-return
    eventNames.map(eventName => {
      map[eventName] = {};
    });

    return map;
  };

  on = (
    eventName: BRIDGE_MESSAGE,
    key: string,
    callBack: (messageResponse: MessageResponsePayload) => void,
  ) => {
    this.subscribers[eventName]![key] = callBack;
  };

  off = (eventName: BRIDGE_MESSAGE, key: string) => {
    delete this.subscribers[eventName]![key];
  };

  dispatchEventToSubscribers = (messageObject: WebBridgeMessage) => {
    if (this.subscribers[messageObject.eventName]) {
      const keys = Object.keys(this.subscribers[messageObject.eventName]!);
      keys.forEach(key => {
        this.subscribers[messageObject.eventName]![key](
          messageObject.eventData as MessageResponsePayload,
        );
      });
    }
  };

  ping() {
    this.emitMessage(BRIDGE_MESSAGE.PING, null, true);
  }

  pong() {
    this.emitMessage(BRIDGE_MESSAGE.PONG, null, true);
  }

  emitMessage(
    eventName: BRIDGE_MESSAGE,
    eventData: AcceptedBridgeMessage | null,
    blindCheck?: boolean,
  ) {
    this.emit(
      this.serializeMessage(BRIDGE_MESSAGE[eventName], eventData),
      blindCheck,
    );
  }

  emit(serializedMessage: string, blindCheck?: boolean) {
    if (blindCheck || this.ready) {
      WebViewBridge.send(serializedMessage);
    }
  }

  serializeMessage(
    eventName: BRIDGE_MESSAGE,
    eventData: AcceptedBridgeMessage | null,
  ): string {
    if (eventData && isEventDataToSend(eventData)) {
      eventData = {
        message: eventData.message,
        name: eventData.name,
        stack: eventData.stack,
      };
    }
    return JSON.stringify({
      eventName,
      eventData,
      eventTimestamp: new Date(),
    });
  }

  unserializeMessage(serializedMessage: string): WebBridgeMessage {
    // eslint-disable-next-line no-useless-catch
    try {
      const messageObject: WebBridgeMessage = JSON.parse(serializedMessage);
      if (messageObject.eventName && BRIDGE_MESSAGE[messageObject.eventName]) {
        if (
          messageObject.eventData !== undefined &&
          messageObject.eventTimestamp
        ) {
          const a = {
            eventName: messageObject.eventName,
            eventData: messageObject.eventData,
            eventTimestamp: messageObject.eventTimestamp,
          };
          return a;
        }
        throw new Error('bridge_message_incorrect_format');
      } else {
        throw new Error('bridge_message_not_found');
      }
    } catch (e) {
      throw e;
    }
  }

  startReadContactless(): Promise<string> {
    return new Promise((resolve, reject) => {
      const readMessage: string[] = [];
      const onKeyCodeEvent = (keycode: KeyboardEvent) => {
        return onKeyPress(keycode, readMessage, badge => {
          resolve(badge);
          window.removeEventListener('keypress', onKeyCodeEvent);
        });
      };

      window.addEventListener('keypress', onKeyCodeEvent);

      setTimeout(() => {
        if (readMessage.length === 0) {
          window.removeEventListener('keypress', onKeyCodeEvent);
          reject(new TimeoutError());
        }
      }, BADGE_READER_TIMEOUT);
    });
  }
}

export const WebBridgeServiceFactory = (function() {
  let instance: WebBridge;
  return {
    getInstance() {
      if (instance == null) {
        instance = new WebBridge();
      }
      return instance;
    },
  };
})();
