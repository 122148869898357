import {
  put,
  takeLatest,
  take,
  delay,
  call,
  race,
  select,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { Action } from 'redux';
import { push } from 'connected-react-router';
import { getCustomerFromBadgeNumber } from 'services/innovorder';

import { startPayment, selectAmount } from 'redux/reload/reload.actions';
import { getToken } from 'redux/app/app.selectors';

import { TimeoutError, WebBridgeServiceFactory } from 'services/webBridge';
import {
  waitingForBadge,
  customerUpdated,
  disconnectCustomer,
  restartInctivityWatcher,
  customerFailedToAuthenticate,
} from './customer.actions';

export const INACTIVITY_TIMEOUT = 1000 * 25;

function* waitingForBadgeHandler(): Saga {
  const authToken = yield select(getToken);
  const webBridgeService = WebBridgeServiceFactory.getInstance();

  try {
    const badgeResponse = yield call(webBridgeService.startReadContactless);
    const customer: AsyncReturnType<typeof getCustomerFromBadgeNumber> = yield call(
      getCustomerFromBadgeNumber,
      badgeResponse,
      authToken,
    );
    yield put<Action>(customerUpdated(customer));
    yield put<Action>(push('/reload'));
  } catch (e) {
    if (e instanceof TimeoutError) {
      yield put<Action>(disconnectCustomer());
      yield put<Action>(push('/'));
    } else {
      yield put<Action>(customerFailedToAuthenticate());
    }
  }
}

function* authenticationFailedHandler(): Saga {
  yield put<Action>(waitingForBadge());
}

function* inactivityWatcher(): Saga {
  const { action, reload, login } = yield race({
    action: take(getType(selectAmount)),
    login: take(getType(customerUpdated)),
    reload: delay(INACTIVITY_TIMEOUT),
    payment: take(getType(startPayment)),
  });

  if (action || login) {
    yield put<Action>(restartInctivityWatcher());
  }
  if (reload) {
    yield put<Action>(disconnectCustomer());
  }
}

export function* watchCustomerActions(): Saga {
  yield takeLatest(getType(waitingForBadge), waitingForBadgeHandler);
  yield takeLatest(
    getType(customerFailedToAuthenticate),
    authenticationFailedHandler,
  );
  yield takeLatest(getType(customerUpdated), inactivityWatcher);
  yield takeLatest(getType(waitingForBadge), inactivityWatcher);
  yield takeLatest(getType(customerFailedToAuthenticate), inactivityWatcher);
  yield takeLatest(getType(restartInctivityWatcher), inactivityWatcher);
}
